// import { InvoiceTypeIds } from "@/helpers/Constants/General";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import { SaleReturnAPI } from "@/helpers/Apis/Invoices/SalesReturns/SaleReturns";
import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";

const state = {
  salereturns: {},

  errors: [],
  returnDetailsErrors: [],
  isReturnsLoaded: true,
  isEntriesLoaded: true,
};

const getters = {
  getSaleReturns(state) {
    return state.salereturns;
  },
  isReturnsLoaded(state) {
    return state.isReturnsLoaded;
  },
  getReturnErrors(state) {
    return state.errors;
  },
  getInvoiceDetailsErrors(state) {
    return state.returnDetailsErrors;
  },

  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },
};

const mutations = {
  setSaleReturns: (state, payload) => {
    state.salereturns = payload;
  },

  setIsReturnsLoaded: (state, payload) => {
    state.isReturnsLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setExtraEntries(state, payload) {
    // state.returns.data[0].invoice_entries_extra = payload;
    state.salereturns.invoice_entries_extra = payload;
  },

  addInvoiceEntries(state, payload) {
    state.salereturns.invoice_entries_extra = [
      ...state.salereturns.invoice_entries_extra,
      ...payload,
    ];
    let expenses = toFixedIfNecessary(
      state.salereturns.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
      2
    );
    state.salereturns.expenses = expenses;
  },
  setInvoiceExpenses(state, payload) {
    if (payload) {
      state.salereturns.expenses = payload;
    } else {
      let expenses = toFixedIfNecessary(
        state.salereturns.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
        2
      );
      state.salereturns.expenses = expenses;
    }
  },
  setIsEntriesLoaded(state, payload) {
    state.isEntriesLoaded = payload;
  },
};

const actions = {
  fetchSaleReturns: ({ commit }, id) => {
    commit("setIsReturnsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      SaleReturnAPI.fetchSaleReturns(id)
        .then((res) => {
          commit("setSaleReturns", res.data.data);
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsReturnsLoaded", true);
          reject(error);
        });
    });
  },
  previousInvoice: ({ commit }, id) => {
    commit("setIsReturnsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      SaleReturnAPI.previousSaleReturns(id)
        .then((res) => {
          commit("setSaleReturns", res.data.data);
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/salesreturns/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsReturnsLoaded", true);
          reject(error);
        });
    });
  },
  nextInvoice: ({ commit }, id) => {
    commit("setIsReturnsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      SaleReturnAPI.nextSaleReturns(id)
        .then((res) => {
          commit("setSaleReturns", res.data.data);
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/salesreturns/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsReturnsLoaded", true);
          reject(error);
        });
    });
  },

  createSaleReturn: ({ commit }, data) => {
    commit("setIsReturnsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      SaleReturnAPI.createSaleReturn(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setSaleReturns", invoice);
          router.push({
            path: `/salesreturns/${invoice.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateSaleReturn: ({ commit }, data) => {
    commit("setIsReturnsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      SaleReturnAPI.updateSaleReturn(data)
        .then((res) => {
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");

          let invoice = res.data.data;
          commit("setSaleReturns", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  deleteSaleReturn: ({ commit }, data) => {
    commit("setIsReturnsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      const response = SaleReturnAPI.deleteSaleReturn(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  createCustomEntry: ({ state, commit }, data) => {
    commit("setIsEntriesLoaded", false);
    let invoiceData = state.salereturns;
    let mergedData = { ...data, invoiceData: invoiceData };
    return new Promise((resolve, reject) => {
      EntryDetailAPI.createCustomEntry(mergedData)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsEntriesLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
