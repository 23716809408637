import auth from "@/router/middleware/auth";
// import guest from "@/router/middleware/guest";

function lazyLoad(folderName, view) {
  return () =>
    import(/* webpackChunkName: "transaction-reports" */ `@containers/${folderName}/${view}.vue`);
}

const ReportRoutes = [
  {
    path: "/pricedisplay",
    name: "PriceDisplay",
    component: lazyLoad("Constructions/Item", "PriceDisplay"),
    meta: {
      middleware: [auth],
    },
  },
  /// Store Reports
  {
    path: "/storelog",
    name: "StoreLog",
    component: lazyLoad("Constructions/Store", "StoreLog"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/store/report",
    name: "StoreReport",
    component: lazyLoad("Constructions/Store", "Reports/StoreReport"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/inventorycount",
    name: "InventoryCount",
    component: lazyLoad("Constructions/Store", "Reports/InventoryCount"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/generalinventorycount",
    name: "GeneralInventoryCount",
    component: lazyLoad("Constructions/Store", "Reports/GeneralInventoryCount"),
    meta: {
      middleware: [auth],
    },
  },

  /// Items Serials Reports
  {
    path: "/serials",
    name: "Serials",
    component: lazyLoad("Constructions/Item", "Reports/Serials"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/serialtransactions",
    name: "SerialTransactions",
    component: lazyLoad("Constructions/Item", "Reports/SerialTransactions"),
    meta: {
      middleware: [auth],
    },
  },
  /// Items Expire Report
  {
    path: "/expiretransactions",
    name: "ExpireTransactions",
    component: lazyLoad("Constructions/Item", "Reports/ExpireTransactions"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/expirequantities",
    name: "ExpireQuantities",
    component: lazyLoad("Constructions/Item", "Reports/ExpireQuantities"),
    meta: {
      middleware: [auth],
    },
  },
];

export default ReportRoutes;
