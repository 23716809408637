import auth from "@/router/middleware/auth";
// import guest from "@/router/middleware/guest";
const Home = () => import("@views/Home.vue");

function lazyLoad(folderName, view) {
  return () => import(/* webpackChunkName: "general" */ `@containers/${folderName}/${view}.vue`);
}

const GeneralRoutes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/dbsettings",
    name: "dbsettings",
    component: lazyLoad("DBSettings", "DBSettings"),
    // component: () => import(/* webpackChunkName: "general" */ `@containers/DBSettings/DBSettings.vue`),
    meta: {
      middleware: [auth],
    },
  },

  // { path: '*', component: NotFound }
];

export default GeneralRoutes;
