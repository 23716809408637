import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import { StoreOutAPI } from "@/helpers/Apis/Invoices/StoreInOut/StoreOuts";
import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";

const state = {
  storeout: {},

  errors: [],
  storeInDetailsErrors: [],
  isStoreOutsLoaded: true,
  isEntriesLoaded: true,
};

const getters = {
  getStoreOut(state) {
    return state.storeout;
  },

  isStoreOutsLoaded(state) {
    return state.isStoreOutsLoaded;
  },

  getStoreOutErrors(state) {
    return state.errors;
  },

  getStoreOutDetailsErrors(state) {
    return state.storeInDetailsErrors;
  },

  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },
};

const mutations = {
  setStoreOut: (state, payload) => {
    state.storeout = payload;
  },

  setIsStoreOutsLoaded: (state, payload) => {
    state.isStoreOutsLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setExtraEntries(state, payload) {
    state.storeout.invoice_entries_extra = payload;
  },

  addInvoiceEntries(state, payload) {
    state.storeout.invoice_entries_extra = [...state.storeout.invoice_entries_extra, ...payload];
    let expenses = toFixedIfNecessary(
      state.storeout.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
      2
    );
    state.storeout.expenses = expenses;
  },
  setInvoiceExpenses(state, payload) {
    if (payload) {
      state.storeout.expenses = payload;
    } else {
      let expenses = toFixedIfNecessary(
        state.storeout.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
        2
      );
      state.storeout.expenses = expenses;
    }
  },
  setIsEntriesLoaded(state, payload) {
    state.isEntriesLoaded = payload;
  },
};

const actions = {
  fetchStoreOut: ({ commit }, data) => {
    commit("setIsStoreOutsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      StoreOutAPI.fetchStoreOut(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setStoreOut", res.data.data);
          commit("setIsStoreOutsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsStoreOutsLoaded", true);
          reject(error);
        });
    });
  },
  previousInvoice: ({ commit }, id) => {
    commit("setIsStoreOutsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      StoreOutAPI.previousStoreOut(id)
        .then((res) => {
          commit("setStoreOut", res.data.data);
          commit("setIsStoreOutsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/storeouts/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsStoreOutsLoaded", true);
          reject(error);
        });
    });
  },
  nextInvoice: ({ commit }, id) => {
    commit("setIsStoreOutsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      StoreOutAPI.nextStoreOut(id)
        .then((res) => {
          commit("setStoreOut", res.data.data);
          commit("setIsStoreOutsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/storeouts/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsStoreOutsLoaded", true);
          reject(error);
        });
    });
  },

  createStoreOut: ({ commit }, data) => {
    commit("setIsStoreOutsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      StoreOutAPI.createStoreOut(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsStoreOutsLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setStoreOut", invoice);
          router.push({
            path: `/storeouts/${invoice.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoreOutsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateStoreOut: ({ commit }, data) => {
    commit("setIsStoreOutsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      StoreOutAPI.updateStoreOut(data)
        .then((res) => {
          commit("setIsStoreOutsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");

          let invoice = res.data.data;
          commit("setStoreOut", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoreOutsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
  deleteStoreOut: ({ commit }, data) => {
    commit("setIsStoreOutsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      StoreOutAPI.deleteStoreOut(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsStoreOutsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoreOutsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  convertToSale: ({ commit }, data) => {
    commit("setIsStoreOutsLoaded", false);
    return new Promise((resolve, reject) => {
      StoreOutAPI.convertToSale(data)
        .then((res) => {
          commit("setIsStoreOutsLoaded", true);
          commit("setErrors", "");

          router.push({
            path: `/sales/${res.data.data}`,
          });
          commit("setStoreOut", {});
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoreOutsLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  createCustomEntry: ({ state, commit }, data) => {
    /// not using  but gonna use Both Account not single
    commit("setIsEntriesLoaded", false);
    let invoiceData = state.storeout;
    let mergedData = { ...data, invoiceData: invoiceData };
    return new Promise((resolve, reject) => {
      EntryDetailAPI.createCustomEntry(mergedData)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsEntriesLoaded", true);
          commit("addInvoiceEntries", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
