import { BeginningEntryAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  mainaccs: [],

  beginningentries: [],
  isBeginningBalanceLoaded: true,
  errors: [],

  //entriesoverlay stuff
  mainaccentries: [],
  isMainAccEntriesLoaded: true,
};

const getters = {
  getMainAccs(state) {
    return state.mainaccs;
  },
  getBeginningEntries(state) {
    return state.beginningentries;
  },

  isBeginningBalanceLoaded(state) {
    return state.isBeginningBalanceLoaded;
  },
  getBeginningDetailErrors(state) {
    return state.errors;
  },

  //entriesoverlay stuff
  getMainAccEntries(state) {
    return state.mainaccentries;
  },
  getIsMainAccEntriesLoaded(state) {
    return state.isMainAccEntriesLoaded;
  },
};

const mutations = {
  setMainAccs: (state, payload) => {
    state.mainaccs = payload;
  },
  setIsBeginningBalanceLoaded: (state, payload) => {
    state.isBeginningBalanceLoaded = payload;
  },
  setBeginningEntries: (state, payload) => {
    state.beginningentries = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  changeValuesToMainAccount: (state, payload) => {
    let main_account_id = payload.main_account.id;
    let result = state.mainaccs.find((o) => o.id === main_account_id);

    if (payload.adding) {
      result.total_debits = +result.total_debits + +payload.debit;
      result.total_credits = +result.total_credits + +payload.credit;
    } else {
      result.total_debits = +result.total_debits - +payload.debit;
      result.total_credits = +result.total_credits - +payload.credit;
    }
  },

  //entriesoverlay stuff
  setMainAccEntries(state, payload) {
    state.mainaccentries = payload;
  },
  setIsMainAccEntriesLoaded(state, payload) {
    state.isMainAccEntriesLoaded = payload;
  },
  setCurrentPage(state, payload) {
    state.mainaccentries.meta.current_page = payload.value;
  },
};

const actions = {
  fetchBeginningMainAccs: ({ commit }, branchId) => {
    commit("setIsBeginningBalanceLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      BeginningEntryAPI.fetchBeginningMainAccs(branchId)
        .then((response) => {
          commit("setMainAccs", response.data.data);
          commit("setIsBeginningBalanceLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(response.data.data);
        })
        .catch((error) => {
          commit("setIsBeginningBalanceLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },

  fetchMainAccEntries: ({ commit }, data) => {
    commit("setIsMainAccEntriesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      BeginningEntryAPI.fetchMainAccEntries(data)
        .then((res) => {
          commit("setMainAccEntries", res.data);
          commit("setIsMainAccEntriesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMainAccEntriesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },

  fetchBalanceEntries: ({ commit }) => {
    commit("setIsBeginningBalanceLoaded", false);
    return new Promise((resolve, reject) => {
      BeginningEntryAPI.fetchBalanceEntries()
        .then((res) => {
          commit("setBeginningEntries", res.data);
          commit("setIsBeginningBalanceLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsBeginningBalanceLoaded", true);
          reject(error);
        });
    });
  },

  createEntryDetail: ({ commit }, data) => {
    commit("setIsBeginningBalanceLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      BeginningEntryAPI.createEntryDetail(data)
        .then((response) => {
          if (response.status == 200) {
            commit("setErrors", "");
          }
          commit("setIsBeginningBalanceLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("setIsBeginningBalanceLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateEntryDetail: ({ commit }, data) => {
    commit("setIsBeginningBalanceLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      BeginningEntryAPI.updateEntryDetail(data)
        .then((res) => {
          if (res.status == 200) {
            commit("setErrors", "");
          }
          commit("App/setAppLoader", false, { root: true });
          commit("setIsBeginningBalanceLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsBeginningBalanceLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  createUpdateManyDetails: ({ commit }, data) => {
    commit("setIsBeginningBalanceLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      BeginningEntryAPI.createUpdateManyDetails(data)
        .then((res) => {
          if (res.status == 200) {
            commit("setErrors", "");
          }
          commit("App/setAppLoader", false, { root: true });
          commit("setIsBeginningBalanceLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsBeginningBalanceLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  deleteBeginEntryDetail: ({ commit }, id) => {
    commit("setIsBeginningBalanceLoaded", false);
    return new Promise((resolve, reject) => {
      BeginningEntryAPI.deleteEntryDetail(id)
        .then((res) => {
          commit("setIsBeginningBalanceLoaded", true);
          if (res.status == 200) {
            commit("setErrors", "");
          }
          resolve(res);
        })
        .catch((error) => {
          commit("setIsBeginningBalanceLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
