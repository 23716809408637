import { InvoiceTypeIds } from "@/helpers/Constants/General";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import { StoreInAPI } from "@/helpers/Apis/Invoices/StoreInOut/StoreIns";
import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";

const state = {
  storein: {},

  errors: [],
  storeInDetailsErrors: [],
  isStoreInsLoaded: true,
  isEntriesLoaded: true,
};

const getters = {
  getStoreIn(state) {
    return state.storein;
  },

  isStoreInsLoaded(state) {
    return state.isStoreInsLoaded;
  },

  getStoreInErrors(state) {
    return state.errors;
  },

  getStoreInDetailsErrors(state) {
    return state.storeInDetailsErrors;
  },

  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },
};

const mutations = {
  setStoreIn: (state, payload) => {
    state.storein = payload;
  },

  setIsStoreInsLoaded: (state, payload) => {
    state.isStoreInsLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setExtraEntries(state, payload) {
    state.storein.invoice_entries_extra = payload;
  },

  addInvoiceEntries(state, payload) {
    state.storein.invoice_entries_extra = [...state.storein.invoice_entries_extra, ...payload];
    let expenses = toFixedIfNecessary(
      state.storein.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
      2
    );
    state.storein.expenses = expenses;
  },
  setInvoiceExpenses(state, payload) {
    if (payload) {
      state.storein.expenses = payload;
    } else {
      let expenses = toFixedIfNecessary(
        state.storein.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
        2
      );
      state.storein.expenses = expenses;
    }
  },
  setIsEntriesLoaded(state, payload) {
    state.isEntriesLoaded = payload;
  },
};

const actions = {
  fetchStoreIn: ({ commit }, id) => {
    commit("setIsStoreInsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      StoreInAPI.fetchStoreIn(id)
        .then((res) => {
          commit("setErrors", "");
          commit("setStoreIn", res.data.data);
          commit("setIsStoreInsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsStoreInsLoaded", true);
          reject(error);
        });
    });
  },
  previousInvoice: ({ commit }, id) => {
    commit("setIsStoreInsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      StoreInAPI.previousStoreIn(id)
        .then((res) => {
          commit("setStoreIn", res.data.data);
          commit("setIsStoreInsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/storeins/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsStoreInsLoaded", true);
          reject(error);
        });
    });
  },
  nextInvoice: ({ commit }, id) => {
    commit("setIsStoreInsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      StoreInAPI.nextStoreIn(id)
        .then((res) => {
          commit("setStoreIn", res.data.data);
          commit("setIsStoreInsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/storeins/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsStoreInsLoaded", true);
          reject(error);
        });
    });
  },

  createStoreIn: ({ commit }, data) => {
    commit("setIsStoreInsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      StoreInAPI.createStoreIn(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsStoreInsLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setStoreIn", invoice);
          router.push({
            path: `/storeins/${invoice.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoreInsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateStoreIn: ({ commit }, data) => {
    commit("setIsStoreInsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      StoreInAPI.updateStoreIn(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsStoreInsLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setStoreIn", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoreInsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
  deleteStoreIn: ({ commit }, data) => {
    commit("setIsStoreInsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      StoreInAPI.deleteStoreIn(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsStoreInsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoreInsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  convertToPurchase: ({ commit }, data) => {
    commit("setIsStoreInsLoaded", false);
    return new Promise((resolve, reject) => {
      StoreInAPI.convertToPurchase(data)
        .then((res) => {
          commit("setIsStoreInsLoaded", true);
          commit("setErrors", "");

          router.push({
            path: `/purchases/${res.data.data}`,
          });
          commit("setStoreIn", {});
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoreInsLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  createCustomEntry: ({ state, commit }, data) => {
    /// not using  but gonna use Both Account not single
    commit("setIsEntriesLoaded", false);
    let invoiceData = state.storein;
    let mergedData = { ...data, invoiceData: invoiceData };
    return new Promise((resolve, reject) => {
      EntryDetailAPI.createCustomEntry(mergedData)
        .then((res) => {
          commit("setIsEntriesLoaded", true);
          commit("setErrors", "");
          commit("addInvoiceEntries", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
