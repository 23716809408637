import baseUrl from "@/constants/API";

export const EmployeeAPI = {
  fetchEmployees(data) {
    return axios.post(baseUrl() + `/employees/get?page=${data.pageNumber}`, data.data);
  },
  fetchBasicSalesAgents(branchId) {
    return axios.get(baseUrl() + `/employees/basic/salesagents/${branchId ?? null}`);
  },
  fetchBasicCouriers(branchId) {
    return axios.get(baseUrl() + `/employees/basic/couriers/${branchId ?? null}`);
  },
  fetchBasicEmployees(branchId) {
    return axios.get(baseUrl() + `/employees/basic/${branchId ?? null}`);
  },
  fetchEmployeesWithEntries(data) {
    return axios.post(baseUrl() + `/employees/withentries`, data);
  },

  createEmployee(data) {
    return axios.post(baseUrl() + `/employees/create`, data);
  },
  updateEmployee(data) {
    return axios.post(baseUrl() + `/employees/update/${data.id}`, data.data);
  },
  deleteEmployee(data) {
    return axios.delete(baseUrl() + `/employees/delete/${data}`);
  },

  fetchEmployeeLoans(id) {
    return axios.get(baseUrl() + `/employees/emploans/${id}`);
  },
  giveSalary(data) {
    return axios.post(baseUrl() + "/employees/givesalary", data);
  },
  giveLoan(data) {
    return axios.post(baseUrl() + "/employees/giveloan", data);
  },

  salesAgentsCollections(data) {
    return axios.post(
      baseUrl() + `/employees/salesagentscollections?page=${data.pageNumber}`,
      data.data
    );
  },
  salesAgentsClients(data) {
    return axios.post(
      baseUrl() + `/employees/salesagentsclients?page=${data.pageNumber}`,
      data.data
    );
  },
  salesAgentsItems(data) {
    return axios.post(baseUrl() + `/employees/salesagentsitems?page=${data.pageNumber}`, data.data);
  },

  clientEmployees(account) {
    return axios.post(baseUrl() + `/employees/clientemployees`, account);
  },
  clientEmployee(account) {
    return axios.post(baseUrl() + `/employees/clientemployee`, account);
  },

  salesAgentCommission(data) {
    return axios.post(baseUrl() + `/employees/salesagentcommission`, data);
  },
  paySalesAgentCommission(data) {
    return axios.post(baseUrl() + `/employees/paysalesagentcommission`, data);
  },
};
