const Permissions = [
  "admin",
  "general-disallowchangingtodaydate",
  "general-disallowmovementaftertoday",
  "general-viewprofitandcost",
  "general-clientsreceivable",
  "general-supplierspayable",
  "general-ledger",
  "general-finalbalancesheet",

  "beginningstore-view",
  "beginningstore-create",
  "beginningstore-update",
  "beginningstore-delete",
  "beginningstore-export_table",
  "beginningstore-import_table",

  "beginningbalance-view",
  "beginningbalance-create",
  "beginningbalance-update",
  "beginningbalance-delete",
  "beginningbalance-export_table",
  "beginningbalance-import_table",

  "fixedassets-view",
  "fixedassets-create",
  "fixedassets-update",
  "fixedassets-delete",
  "fixedassets-calculate_depreciation_ratio",

  "client-view",
  "client-create",
  "client-update",
  "client-delete",
  "client-export_table",
  "client-import_table",

  "supplier-view",
  "supplier-create",
  "supplier-update",
  "supplier-delete",
  "supplier-export_table",
  "supplier-import_table",

  "mainacc-view",
  "mainacc-create",
  "mainacc-update",
  "mainacc-delete",
  "mainacc-export_table",
  "mainacc-import_table",

  "subacc-view",
  "subacc-create",
  "subacc-update",
  "subacc-delete",
  "subacc-export_table",
  "subacc-import_table",

  "branch-view",
  "branch-create",
  "branch-update",
  "branch-delete",

  "drawer-view",
  "drawer-create",
  "drawer-update",
  "drawer-delete",

  "employee-view",
  "employee-create",
  "employee-update",
  "employee-delete",
  "employee-export_table",
  "employee-import_table",
  "employee-givesalary",
  "employee-giveloan",
  "employee-calccommission",
  "employee-paycommission",
  "employeejob-create",
  "employeejob-update",
  "employeejob-delete",

  "store-view",
  "store-create",
  "store-update",
  "store-delete",
  "store-export_table",
  "store-import_table",
  "store-viewqty",
  "store-searchdetail",
  "inventorycount-view",

  "category-view",
  "category-create",
  "category-update",
  "category-delete",
  "category-export_table",
  "category-import_table",

  "item-view",
  "item-create",
  "item-update",
  "item-delete",
  "item-export_table",
  "item-import_table",

  "entry-personal_data",
  "entry-view",
  "entry-create",
  "entry-update",
  "entry-delete",
  "entry-searchdetail",

  "draweraction-withdraw",
  "draweraction-deposit",
  "draweraction-cashtransfer",

  "sale-personal_data",
  "sale-view",
  "sale-create",
  "sale-update",
  "sale-delete",
  "sale-paytype",
  "sale-print_customer_balance",

  "sale-edit_price",
  "sale-edit_discount",
  "sale-edit_discount2",
  "sale-edit_discount3",
  "sale-edit_tax",
  "sale-sell_below_whole",
  "sale-sell_below_halfwhole",
  "sale-sell_below_halfcommercial",
  "sale-sell_below_commercial",
  "sale-sell_below_consumer",
  "sale-sell_bellow_average",
  "sale-sell_below_lastpurchase",
  "sale-deleted_preview",
  "sale-itemsales",
  "sale-saleagents_collection",
  "sale-saleagents_clients",
  "sale-saleagents_items",

  "cashier-view",
  "cashier-create",
  "cashier-update",
  "cashier-delete",
  "cashier-edit_discount",
  "cashier-edit_tax",
  "cashier-deleted_preview",
  "cashier-print_customer_balance",

  "salerelated-create",
  "salerelated-update",
  "salerelated-delete",
  "salerelated-edit_discount",
  "salerelated-edit_discount2",
  "salerelated-edit_discount3",
  "salerelated-edit_tax",
  "salerelated-edit_price",

  "reservation-personal_data",
  "reservation-view",
  "reservation-create",
  "reservation-update",
  "reservation-delete",
  "reservation-edit_price",
  "reservation-edit_discount",
  "reservation-edit_discount2",
  "reservation-edit_discount3",
  "reservation-edit_tax",

  "saleorder-personal_data",
  "saleorder-view",
  "saleorder-create",
  "saleorder-update",
  "saleorder-delete",
  "saleorder-edit_price",
  "saleorder-edit_discount",
  "saleorder-edit_discount2",
  "saleorder-edit_discount3",
  "saleorder-edit_tax",

  "salereturn-personal_data",
  "salereturn-view",
  "salereturn-create",
  "salereturn-update",
  "salereturn-delete",
  "salereturn-paytype",
  "salereturn-print_customer_balance",

  "salereturn-edit_price",
  "salereturn-edit_discount",
  "salereturn-edit_discount2",
  "salereturn-edit_discount3",
  "salereturn-edit_tax",
  "salereturn-deleted_preview",
  "salereturn-itemreturns",

  "purchase-personal_data",
  "purchase-view",
  "purchase-create",
  "purchase-update",
  "purchase-delete",
  "purchase-deleted_preview",
  "purchase-itempurchases",
  "purchase-print_customer_balance",

  "purchaseorder-personal_data",
  "purchaseorder-view",
  "purchaseorder-create",
  "purchaseorder-update",
  "purchaseorder-delete",

  "purchaserelated-create",
  "purchaserelated-update",
  "purchaserelated-delete",

  "purchasereturn-personal_data",
  "purchasereturn-view",
  "purchasereturn-create",
  "purchasereturn-update",
  "purchasereturn-delete",
  "purchasereturn-deleted_preview",
  "purchasereturn-itemreturns",
  "purchasereturn-print_customer_balance",

  "storein-personal_data",
  "storein-view",
  "storein-create",
  "storein-update",
  "storein-delete",
  "storein-edit_price",

  "storeout-personal_data",
  "storeout-view",
  "storeout-create",
  "storeout-update",
  "storeout-delete",
  "storeout-edit_price",

  "transfer-personal_data",
  "transfer-view",
  "transfer-create",
  "transfer-update",
  "transfer-delete",
  "transfer-edit_price",
  "transfer-edit_delivered",
  "transfer-delivered",

  "payable-personal_data",
  "payable-view",
  "payable-create",
  "payable-update",
  "payable-delete",
  "receivable-personal_data",
  "receivable-view",
  "receivable-create",
  "receivable-update",
  "receivable-delete",

  "production-personal_data",
  "production-view",
  "production-create",
  "production-update",
  "production-delete",
  "production-costview",

  "maintenance-personal_data",
  "maintenance-view",
  "maintenance-create",
  "maintenance-update",
  "maintenance-delete",
  "maintenance-edit_price",
  "maintenance-edit_discount",
  "maintenance-edit_tax",

  "restauranttable-view",
  "restauranttable-create",
  "restauranttable-update",
  "restauranttable-delete",

  "restaurantcat-view",
  "restaurantcat-create",
  "restaurantcat-update",
  "restaurantcat-delete",

  "restaurantmeal-view",
  "restaurantmeal-create",
  "restaurantmeal-update",
  "restaurantmeal-delete",

  "restaurantorder-personal_data",
  "restaurantorder-view",
  "restaurantorder-create",
  "restaurantorder-update",
  "restaurantorder-delete",
  "restaurantorder-deleted_preview",
  "restaurantorder-edit_discount",
  "restaurantorder-edit_tax",
  "restaurantorder-edit_service",
  "restaurantorder-edit_delivery",
  "restaurantorder-order_profits",
  "restaurantorder-meal_profits",
  "restaurantorder-meal_transactions",
  "restaurantorder-recipe_transactions",

  "restaurantorder-kitchen_view",
  "restaurantorder-kitchen_update",
  // 'restaurantorder-create_delivery',
  // 'restaurantorder-create_dinein',
  // 'restaurantorder-create_takeaway',

  "cleanning-personal_data",
  "cleanning-view",
  "cleanning-create",
  "cleanning-update",
  "cleanning-delete",
];
export default Permissions;
