import { RestaurantOrderAPI } from "@/helpers/Apis/index";
import { StatusesArray } from "@/helpers/Constants/Restaurant";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";

const state = {
  restaurantOrder: {},

  errors: [],
  restaurantOrderDetailsErrors: [],
  isRestaurantOrdersLoaded: true,
};

const getters = {
  isRestaurantOrdersLoaded(state) {
    return state.isRestaurantOrdersLoaded;
  },
  getRestaurantOrder(state) {
    return state.restaurantOrder;
  },

  getRestaurantOrderErrors(state) {
    return state.errors;
  },

  getRestaurantOrderDetailsErrors(state) {
    return state.restaurantOrderDetailsErrors;
  },
};

const mutations = {
  setIsRestaurantOrdersLoaded: (state, payload) => {
    state.isRestaurantOrdersLoaded = payload;
  },
  setRestaurantOrder: (state, payload) => {
    state.restaurantOrder = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setRestaurantOrderStatus: (state, payload) => {
    state.restaurantOrder.status = payload.id;
    state.restaurantOrder.status_name = payload.name;
  },
};

const actions = {
  fetchRestaurantOrder: ({ commit }, id) => {
    commit("setIsRestaurantOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.fetchRestaurantOrder(id)
        .then((res) => {
          commit("setRestaurantOrder", res.data.data);
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsRestaurantOrdersLoaded", true);
          reject(error);
        });
    });
  },

  createRestaurantOrder: ({ commit }, data) => {
    commit("setIsRestaurantOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.createRestaurantOrder(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setRestaurantOrder", invoice);
          router.push({
            path: `/restaurant/orders/${invoice.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateRestaurantOrder: ({ commit }, data) => {
    commit("setIsRestaurantOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.updateRestaurantOrder(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setRestaurantOrder", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  setRestaurantOrderStatus: ({ commit }, data) => {
    commit("setIsRestaurantOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.setRestaurantOrderStatus(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let status = StatusesArray.find((o) => o.id === data.status);
          commit("setRestaurantOrderStatus", status);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  deleteRestaurantOrder: ({ commit }, data) => {
    commit("setIsRestaurantOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.deleteRestaurantOrder(data)
        .then((res) => {
          commit("setRestaurantOrder", {});
          commit("setErrors", "");
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
