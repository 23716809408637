// import { InvoiceTypeIds } from "@/helpers/Constants/General";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import { SaleAPI } from "@/helpers/Apis/Invoices/Sales/Sales";

import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";

const state = {
  sale: {},

  errors: [],
  saleDetailsErrors: [],
  isSalesLoaded: true,
  isEntriesLoaded: true,
};

const getters = {
  getSale(state) {
    return state.sale;
  },
  isSalesLoaded(state) {
    return state.isSalesLoaded;
  },
  getSaleErrors(state) {
    return state.errors;
  },
  getSaleDetailsErrors(state) {
    return state.saleDetailsErrors;
  },

  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },
};

const mutations = {
  setSale: (state, payload) => {
    state.sale = payload;
  },

  setIsSalesLoaded: (state, payload) => {
    state.isSalesLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setSaleRelated(state, payload) {
    state.sale.related_return = payload;
  },
  setExtraEntries(state, payload) {
    state.sale.invoice_entries_extra = payload;
  },

  addInvoiceEntries(state, payload) {
    state.sale.invoice_entries_extra = [...state.sale.invoice_entries_extra, ...payload];
    let expenses = toFixedIfNecessary(
      state.sale.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
      2
    );
    state.sale.expenses = expenses;
  },
  setInvoiceExpenses(state, payload) {
    if (payload) {
      state.sale.expenses = payload;
    } else {
      let expenses = toFixedIfNecessary(
        state.sale.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
        2
      );
      state.sale.expenses = expenses;
    }
  },
  setIsEntriesLoaded(state, payload) {
    state.isEntriesLoaded = payload;
  },
};

const actions = {
  fetchSale: ({ commit }, id) => {
    commit("setIsSalesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      SaleAPI.fetchSale(id)
        .then((res) => {
          commit("setSale", res.data.data);
          commit("setIsSalesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsSalesLoaded", true);
          reject(error);
        });
    });
  },
  previousInvoice: ({ commit }, id) => {
    commit("setIsSalesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      SaleAPI.previousSale(id)
        .then((res) => {
          commit("setSale", res.data.data);
          commit("setIsSalesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/sales/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsSalesLoaded", true);
          reject(error);
        });
    });
  },
  nextInvoice: ({ commit }, id) => {
    commit("setIsSalesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      SaleAPI.nextSale(id)
        .then((res) => {
          commit("setSale", res.data.data);
          commit("setIsSalesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/sales/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsSalesLoaded", true);
          reject(error);
        });
    });
  },

  createSale: ({ commit }, data) => {
    commit("setIsSalesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      SaleAPI.createSale(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsSalesLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setSale", invoice);
          router.push({
            path: `/sales/${invoice.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSalesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateSale: ({ commit }, data) => {
    commit("setIsSalesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      SaleAPI.updateSale(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsSalesLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setSale", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSalesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
  deleteSale: ({ commit }, data) => {
    commit("setIsSalesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      SaleAPI.deleteSale(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsSalesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSalesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  createCustomEntry: ({ state, commit }, data) => {
    commit("setIsEntriesLoaded", false);
    let invoiceData = state.sale;
    let mergedData = { ...data, invoiceData: invoiceData };
    return new Promise((resolve, reject) => {
      EntryDetailAPI.createCustomEntry(mergedData)
        .then((res) => {
          commit("setIsEntriesLoaded", true);
          commit("setErrors", "");
          commit("addInvoiceEntries", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
