const CompanyPermissions = {
  sale_management: "sale_management",
  purchase_management: "purchase_management",
  storeinout_management: "storeinout_management",
  notes_management: "notes_management",
  maintenance_management: "maintenance_management",
  cleanning_management: "cleanning_management",
  production_management: "production_management",
  restaurant_management: "restaurant_management",
};

export { CompanyPermissions };
