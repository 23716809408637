import baseUrl from "@/constants/API";

export const ItemAPI = {
  fetchAllItems() {
    return axios.get(baseUrl() + `/items/allitems`);
  },

  fetchItemPriceDisplay(data) {
    return axios.get(baseUrl() + `/items/pricedisplay`, {
      params: data,
    });
  },

  fetchItem(id) {
    return axios.get(baseUrl() + `/items/${id}`);
  },
  fetchItems(data) {
    return axios.post(baseUrl() + `/items/get?page=${data.pageNumber}`, data.data);
  },

  createItem(data) {
    return axios.post(baseUrl() + `/items/create`, data);
  },

  updateItem(data) {
    return axios.post(baseUrl() + `/items/update/${data.id}`, data.data);
  },

  deleteItem(id) {
    return axios.delete(baseUrl() + `/items/delete/${id}`);
  },

  increasePrices(data) {
    return axios.post(baseUrl() + "/items/increaseprices", data);
  },
  decreasePrices(data) {
    return axios.post(baseUrl() + "/items/decreaseprices", data);
  },

  ItemsPriceList(data) {
    return axios.post(baseUrl() + `/items/pricelist?page=${data.pageNumber}`, data.data);
  },

  // items extra search functions
  fetchListItems(data) {
    return axios.post(baseUrl() + "/items/searchbycat", data);
  },
  fetchListItem(data) {
    return axios.post(baseUrl() + `/items/itemlist`, data);
  },

  fetchItemByBarcode(data) {
    return axios.post(baseUrl() + `/items/itembarcode`, data);
  },
  searchBarcodeName(data) {
    return axios.post(baseUrl() + "/items/searchbarcodename", data);
  },
  fetchItemBasic(data) {
    let pageNumber = data?.pageNumber ?? 0;
    let dataObject = data?.data;
    return axios.post(baseUrl() + `/items/basic/search?page=${pageNumber}`, dataObject);
  },

  fetchItemsBarcodePrint(data) {
    return axios.post(baseUrl() + `/items/barcodeprint?page=${data.pageNumber}`, data.data);
  },

  getItemUpdatePrices(id) {
    return axios.get(baseUrl() + `/items/getitemupdateprices/${id}`);
  },
  updateItemPrices(data) {
    return axios.post(baseUrl() + `/items/updateprices/${data.id}`, data.data);
  },

  // bonuses
  fetchItemBonuses(id) {
    return axios.get(baseUrl() + `/items/getbonuses/${id}`);
  },
  createBonusItems(data) {
    return axios.post(baseUrl() + "/items/createbonuses", data);
  },
  deleteBonus(id) {
    return axios.delete(baseUrl() + `/items/deletebonus/${id}`);
  },

  // Excel Excel Excel Excel Excel Excel
  exportTable() {
    return axios.post(baseUrl() + `/items/export`, null, {
      responseType: "blob",
    });
  },
  downloadExportFile() {
    return axios.post(baseUrl() + `/items/export/download`, null, {
      responseType: "blob",
    });
  },
  downloadCreateTemplate() {
    return axios.post(baseUrl() + `/items/import/createtemplate`, null, {
      responseType: "blob",
    });
  },
  downloadUpdateTemplate() {
    return axios.post(baseUrl() + `/items/import/updatetemplate`, null, {
      responseType: "blob",
    });
  },
  importCreate(data) {
    return axios.post(baseUrl() + `/items/import/create`, data);
  },
  importUpdate(data) {
    return axios.post(baseUrl() + `/items/import/update`, data);
  },
};
