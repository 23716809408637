<template>
  <v-btn v-on="$listeners" v-bind="{ ...$attrs, ...$props }" small dense>
    <slot name="iconSlot"></slot>
    {{ name }}
  </v-btn>
</template>

<script>
export default {
  props: ["name"],
  methods: {},
};
</script>

<style></style>
