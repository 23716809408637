import baseUrl from "@/constants/API";

export const CategoryAPI = {
  fetchCategory(data) {
    return axios.get(baseUrl() + `/categories/single/${data.id}`);
  },
  fetchCategories() {
    return axios.get(baseUrl() + `/categories/get`);
  },
  fetchCategoriesList() {
    return axios.get(baseUrl() + `/categories/catlist`);
  },

  createCategory(data) {
    return axios.post(baseUrl() + `/categories/create`, data);
  },
  updateCategory(data) {
    return axios.post(baseUrl() + `/categories/update/${data.id}`, data.data);
  },
  deleteCategory(id) {
    return axios.delete(baseUrl() + `/categories/delete/${id}`);
  },
};
