import baseUrl from "@/constants/API";

const createSerial = (data) => {
  return axios.post(baseUrl() + `/items/serials/create`, data);
};

const deleteSerial = (id) => {
  return axios.delete(baseUrl() + `/items/serials/delete/${id}`);
};
const deleteTransaction = (id) => {
  return axios.delete(baseUrl() + `/items/serials/delete/transaction/${id}`);
};

const fetchSerialsFilter = (data) => {
  return axios.post(baseUrl() + `/items/serials/serialsfilter?page=${data.pageNumber}`, data.data);
};

const fetchTransactionsFilter = (data) => {
  return axios.post(
    baseUrl() + `/items/serials/transactionsfilter?page=${data.pageNumber}`,
    data.data
  );
};

const ReviewSerials = (data) => {
  return axios.get(baseUrl() + `/items/serials/review`, data);
};

// Serials Fetch  Serials Fetch  Serials Fetch
const fetchSaleReturnSerials = (data) => {
  return axios.post(baseUrl() + `/items/serials/sreturnserials`, data);
};
const fetchPurchaseReturnSerials = (data) => {
  return axios.post(baseUrl() + `/items/serials/preturnserials`, data);
};
const fetchSerialsRelated = (data) => {
  return axios.post(baseUrl() + `/items/serials/serialsrelated`, data);
};

const fetchSaleSerials = (data) => {
  return axios.post(baseUrl() + `/items/serials/saleserials`, data);
};
const fetchTransferSerials = (data) => {
  return axios.post(baseUrl() + `/items/serials/transferserials`, data);
};

export {
  createSerial,
  fetchSerialsFilter,
  fetchTransactionsFilter,
  deleteSerial,
  deleteTransaction,
  ReviewSerials,

  // Serials Fetch  Serials Fetch  Serials Fetch
  fetchSaleSerials,
  fetchSaleReturnSerials,
  fetchPurchaseReturnSerials,
  fetchSerialsRelated,
  fetchTransferSerials,
};
