import baseUrl from "@/constants/API";

const ReviewExpireDates = () => {
  return axios.get(baseUrl() + `/items/expires/review`);
};

const fetchExpiresTransactionsFiltered = (data) => {
  return axios.post(
    baseUrl() + `/items/expires/transactionsfilter?page=${data.pageNumber}`,
    data.data
  );
  // return axios.post(baseUrl() + "/items/expires/transactionsfilter", data);
};
const fetchExpiresQtyFiltered = (data) => {
  return axios.post(baseUrl() + `/items/expires/qtyfilter?page=${data.pageNumber}`, data.data);
};

// Expire Fetches Expire Fetches Expire Fetches Expire Fetches
const fetchAvailableExpires = (data) => {
  return axios.post(baseUrl() + `/items/expires/availables`, data);
};

const fetchAvailableExpiresRelated = (data) => {
  return axios.post(baseUrl() + "/items/expires/availablesrelated", data);
};

export {
  ReviewExpireDates,
  fetchExpiresTransactionsFiltered,
  fetchExpiresQtyFiltered,

  // Expire Fetches  Expire Fetches  Expire Fetches
  fetchAvailableExpires,
  fetchAvailableExpiresRelated,
};
