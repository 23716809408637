import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// import "vuetify/dist/vuetify.min.css";

// Fonts
// import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      icon: {},
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#3f51b5",
        textColor: "#000000",
        // secondary: "#b0bec5",
        accent: "#8c9eff",
        // error: "#b71c1c",
        headerBG: "#C4CAD0",
        // switchColor: "#1C2541",
        headerHoverBG: "#3f51b5",
        headerHoverText: "#729B79",
        textHover: "#2F315C",

        white: "#fff",
        primaryBlue: "#3f51b5", //#3b3f7f   //#2f3266
        secondaryBlue: "#2c3772",

        //Site Background
        background: "#cccccc",
        // cardBGColor: "#ededed",

        //Select Btn
        selectBG: "#fff",
        selectText: "#000000",

        toolTipBgColor: "#311B92",
      },
      dark: {
        primary: "#3f51b5",
        textColor: "#fff",
        headerBG: "#1C2541",
        // error: "#b71c1c",
        // switchColor: "#C4CAD0",
        headerHoverBG: "#729B79",
        headerHoverText: "#3f51b5",
        textHover: "#2F315C",

        white: "#fff",
        primaryBlue: "#3f51b5", //#3b3f7f   //#2f3266
        secondaryBlue: "#2c3772",

        //Site Background
        background: "#121212", //black
        // background: "#1E1E1E",
        // cardBGColor: "#1E1E1E",

        //Select Btn
        selectBG: "#121212",
        selectText: "#fff",

        toolTipBgColor: "#311B92",
      },
    },
  },
});
