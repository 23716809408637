<template>
  <v-list-item
    class="pa-0"
    dense
    v-if="isUserLoaded && Object.keys(getUser).length > 0"
  >
    <v-list-item-content class="pa-0">
      <v-list-item-title class="text-h6 text-center pa-1" v-if="getUser.name">
        <v-badge
          class="ma-0"
          :color="getOnlineStatus ? 'green' : 'red'"
          overlap
          dot
        >
          <v-menu bottom min-width="200px" rounded offset-y>
            <template v-slot:activator="{ on }">
              <!-- tile -->
              <!-- <v-avatar v-on="on" size="70" class="pointer-cursor">
                <img :src="getCompanyData.logo" alt="CompanyLogo" />
              </v-avatar> -->
              <v-btn icon x-large v-on="on">
                <v-avatar color="brown" size="48">
                  <span class="white--text text-h6"> {{ initials }} </span>
                </v-avatar>
              </v-btn>
            </template>
            <v-card>
              <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                  <div class="pa-1">
                    {{ getUser.name }}
                  </div>

                  <v-divider class="my-1"></v-divider>
                  <BasicBtn
                    :name="$t('userprofile')"
                    depressed
                    rounded
                    text
                    @click="userProfile"
                  />

                  <v-divider class="my-1"></v-divider>
                  <BasicBtn
                    :name="$t('reloaduser')"
                    depressed
                    rounded
                    text
                    @click="reloadUserBtn"
                  />

                  <v-divider class="my-1"></v-divider>
                  <BasicBtn
                    :name="$t('changedatabase')"
                    depressed
                    rounded
                    text
                    @click="changeDB"
                  />

                  <v-divider
                    v-if="getUser && getUser.role.id == 1"
                    class="my-1"
                  ></v-divider>
                  <BasicBtn
                    v-if="getUser && getUser.role.id == 1"
                    name="Download DB"
                    depressed
                    rounded
                    text
                    :loading="!isUserControlLoaded"
                    :disabled="!isUserControlLoaded"
                    @click="downloadDB"
                  />

                  <v-divider class="my-1"></v-divider>
                  <BasicBtn
                    :name="$t('nav.logout')"
                    depressed
                    rounded
                    text
                    @click="accountLogout()"
                  />
                </div>
              </v-list-item-content>
            </v-card>
          </v-menu>
        </v-badge>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["getOnlineStatus"],
  data: () => ({}),
  components: {},
  methods: {
    ...mapActions("UsersController", ["downloadDB"]),
    ...mapActions("User", ["reloadUser"]),

    accountLogout() {
      this.$emit("accountLogout");
    },
    userProfile() {
      this.$router.push({
        path: `/userprofile`,
      });
    },
    changeDB() {
      this.$router.push({
        path: `/changedatabase`,
      });
    },

    reloadUserBtn() {
      this.reloadUser();
    },
  },
  computed: {
    ...mapGetters("UsersController", ["isUserControlLoaded"]),
    initials() {
      return this.getUser.name
        .split(" ")
        .filter((name) => name.length > 0)
        .reduce(
          (initials, name, index) =>
            initials + (index < 2 ? name[0].toUpperCase() : ""),
          ""
        );
    },
  },
};
</script>

<style></style>
